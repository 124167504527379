import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Link } from "gatsby";
import SEO from "../components/seo";
import Layout from "../components/layout";
import "../styles/recommendations.scss";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout mdxType="Layout">
      <SEO title="We're hiring a producer" mdxType="SEO" />
      <h1>We're hiring a producer</h1>
      <hr className="line" />
      <div className="producer">
        <p><strong parentName="p">{`Note`}</strong>{`: you can also see this job advert on this `}<a parentName="p" {...{
            "href": "https://docs.google.com/document/d/12Dm1Y9WvR644VGgCtI0h29pzCR4z84Nm6iZ28xThLMw/edit?usp=sharing"
          }}>{`google doc`}</a>{`.`}</p>
        <p><strong parentName="p">{`Job Description:`}</strong>{` Hear This Idea is looking for a podcast producer to help with producing and growing the show. We are primarily looking for someone to help edit our episodes, and possibly also graphics and other aspects of production. The producer will work with and under the close supervision of the two co-hosts (Fin Moorhouse and Luca Righetti). `}</p>
        <p>{`This is the first contracting work that Hear This Idea is trialing, so responsibilities will likely adapt to the candidate and evolve over time. The most urgent work we are currently looking to fill is editing the audio of our interviews. As a second priority, we are also looking to delegate various content creation tasks, and in the future other potential responsibilities too (see below).`}</p>
        <p>{`We are open to applications from people who are interested in doing only audio editing or only e.g. graphics work, although we would have a weak preference for someone who has potential to eventually do both.`}</p>
        <p><strong parentName="p">{`Location(s):`}</strong>{` Remote`}</p>
        <p><strong parentName="p">{`Hours:`}</strong>{` Part-time (circa 4-8 hours a week); flexible hours.`}</p>
        <p><strong parentName="p">{`Contract Type:`}</strong>{` Contracting`}</p>
        <p><strong parentName="p">{`Compensation:`}</strong>{` $20-$30 per hour (depending on experience)`}</p>
        <p><strong parentName="p">{`Core Responsibilities:`}</strong></p>
        <ol>
          <li parentName="ol">{`Editing audio content to a high standard`}</li>
        </ol>
        <p><strong parentName="p">{`Additional responsibilities:`}</strong></p>
        <ol>
          <li parentName="ol">{`Creating design materials such as thumbnails, audiograms, and logos`}</li>
          <li parentName="ol">{`Publishing podcast content to audio and video feeds`}</li>
          <li parentName="ol">{`Adding new website pages and additional features`}</li>
        </ol>
        <p><strong parentName="p">{`Potential future responsibilities:`}</strong></p>
        <ol>
          <li parentName="ol">{`Creating and tracking analytics to help understand trends and best practices`}</li>
          <li parentName="ol">{`Helping to launch new initiatives (e.g. book giveaways, listener surveys, collaborations with other organisations)`}</li>
          <li parentName="ol">{`Working with the co-hosts to improve the overall content and quality of the show`}</li>
        </ol>
        <p><strong parentName="p">{`Required Traits/Qualifications:`}</strong></p>
        <ol>
          <li parentName="ol">{`Self-motivated and independent; highly detail-oriented; industrious and organized; flexible and generally capable`}</li>
          <li parentName="ol">{`Fluent in English`}</li>
          <li parentName="ol">{`Enjoys engaging with and creating audio and visual content`}</li>
          <li parentName="ol">{`Previous experience with audio and video editing programs (e.g. Reaper, Adobe Audition, Audacity)`}</li>
          <li parentName="ol">{`Familiar with effective altruism`}</li>
        </ol>
        <p><strong parentName="p">{`Preferred Traits/Qualifications:`}</strong></p>
        <ol>
          <li parentName="ol">{`Previous experience with podcasting, YouTube, and content creation.`}</li>
          <li parentName="ol">{`Experience with GatsbyJS, git, and web development`}</li>
          <li parentName="ol">{`Interest in social sciences and/or philosophy`}</li>
        </ol>
        <p><strong parentName="p">{`Application Procedures:`}</strong></p>
        <ul>
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`Stage 1:`}</strong>{` Fill out `}<a parentName="p" {...{
                "href": "https://docs.google.com/forms/d/e/1FAIpQLSdolEccncHPw_uZZQfHHDZWXe825J7jk3RIAHkwyFsz_4arpA/viewform?usp=sf_link"
              }}>{`this application form`}</a>{` and respond to the prompts`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`Stage 2:`}</strong>{` Interviews with the co-hosts`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`Stage 3:`}</strong>{` Paid work trial (helping to produce an episode)`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`Deadline:`}</strong>{` December 15th, 23.59 GMT, with rolling applications thereafter.`}</p>
          </li>
        </ul>
        <p>{`Questions or concerns may be directed to `}<a parentName="p" {...{
            "href": "mailto:producer@hearthisidea.com"
          }}>{`producer@hearthisidea.com`}</a></p>
      </div>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      